import BaseDrawable from './base-drawable';
import { Extents, Vector } from './shared';
import { getRandomBetween } from './shared/utils';

class Ball {
  size: Vector;
  bounds: Vector;
  position: Vector;
  velocity: Vector;
  speedScale: number;
  speedMax: number;
  hit: number;

  constructor(w: number, h: number, speedScale = 1.8, speedMax = 200) {
    this.size = new Vector(32, 32);
    this.bounds = new Vector(w, h);
    this.position = new Vector(
      getRandomBetween(0, this.bounds.x - this.size.x),
      getRandomBetween(0, this.bounds.y - this.size.y)
    );
    this.velocity = new Vector(
      getRandomBetween(-64, 64),
      getRandomBetween(-64, 64)
    );
    this.speedScale = speedScale;
    this.speedMax = speedMax;
    this.hit = 0;
  }

  move(dt: number) {
    this.position.x += this.velocity.x * dt;
    this.position.y += this.velocity.y * dt;

    if (this.position.x < 0 || this.position.x + this.size.x > this.bounds.x) {
      this.velocity.x = -this.velocity.x;
      this.velocity.x = this.velocity.x * this.speedScale;
      this.velocity.y = this.velocity.y * this.speedScale;
      this.hit++;
    }
    if (this.position.y < 0 || this.position.y + this.size.y > this.bounds.y) {
      this.velocity.y = -this.velocity.y;
      this.velocity.x = this.velocity.x * this.speedScale;
      this.velocity.y = this.velocity.y * this.speedScale;
      this.hit++;
    }
  }

  get pregnant() {
    return this.velocity.magnitude > this.speedMax;
  }

  birth() {
    if (this.velocity.magnitude > this.speedMax) {
      this.velocity.x = this.velocity.x / 5;
      this.velocity.y = this.velocity.y / 5;
    }
    return new Ball(this.bounds.x, this.bounds.y);
  }

  get alive() {
    return this.hit < 10;
  }

  get color() {
    return `rgba(0, 0, 0, ${1 - this.hit / 10})`;
  }
}

export default class Blocks extends BaseDrawable {
  private _balls: Ball[] = [];
  private _last?: number;
  maxPopulation: number;

  constructor(population: number = 20) {
    super();
    this.maxPopulation = population;
  }

  draw(context: CanvasRenderingContext2D, extents: Extents, millis: number) {
    if (this._balls.length === 0) {
      this._balls = [new Ball(extents.width, extents.height)];
    }
    if (this._last !== undefined) {
      this.update((millis - this._last) / 1000);
      this._drawStage(context, extents);
      this._balls.forEach((ball) => this._drawBall(context, ball));
    }
    this._last = millis;
  }

  _drawStage(context: CanvasRenderingContext2D, extents: Extents) {
    context.fillStyle = '#fff';
    context.fillRect(0, 0, extents.width, extents.height);
  }

  _drawBall(context: CanvasRenderingContext2D, ball: Ball) {
    context.fillStyle = ball.color;
    context.fillRect(
      ball.position.x,
      ball.position.y,
      ball.size.x,
      ball.size.y
    );
  }

  update(dt: number) {
    this._balls.forEach((x) => x.move(dt));
    const children = this._balls
      .filter((x) => x.pregnant)
      .map((x) => x.birth());

    const balls = [...this._balls.filter((x) => x.alive), ...children];
    this._balls =
      balls.length > this.maxPopulation
        ? balls.slice(0, this.maxPopulation)
        : balls;
  }
}
