import { Extents } from './shared';

export default abstract class BaseDrawable {
  abstract draw(
    context: CanvasRenderingContext2D,
    extents: Extents,
    millis: number
  ): void;

  abstract update(dt: number, extents: Extents): void;
}
