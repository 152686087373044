export default class Vector {
  x: number;
  y: number;

  constructor(x = 0, y = 0) {
    this.x = x;
    this.y = y;
  }

  add(v: Vector) {
    return new Vector(this.x + v.x, this.y + v.y);
  }

  multiply(n: number) {
    return new Vector(n * this.x, n * this.y);
  }

  dot(v: Vector) {
    return this.x * v.x + this.y * v.y;
  }

  get magnitude() {
    return Math.sqrt(this.x ** 2 + this.y ** 2);
  }

  get unit() {
    return new Vector(this.x / this.magnitude, this.y / this.magnitude);
  }
}
