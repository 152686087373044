export default class Extents {
  width: number;
  height: number;

  constructor(width = 0, height = 0) {
    this.width = width;
    this.height = height;
  }
}

export class Bounds {
  top: number;
  left: number;
  right: number;
  bottom: number;

  constructor(top: number, left: number, right: number, bottom: number) {
    this.top = top;
    this.left = left;
    this.right = right;
    this.bottom = bottom;
  }

  static fromExtents(extents: Extents) {
    return new Bounds(0, 0, extents.width, extents.height);
  }
}
