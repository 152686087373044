import { Extents, Point, Vector } from './shared';
import { Bounds } from './shared/extents';
import { getRandomBetween } from './shared/utils';
import LineArt, { Particle as LineParticle } from './line-art';

class Particle extends LineParticle {
  draw(context: CanvasRenderingContext2D, extents: Extents, millis: number) {
    super.draw(context, extents, millis);

    // context.moveTo(this.position.x, this.position.y);
    // const pv = new Vector(this.position.x, this.position.y);
    // const v = this.velocity.add(pv);
    // context.lineTo(v.x, v.y);
    // context.stroke();
  }

  update(dt: number, extents: Extents) {
    this.position.x += this.velocity.x * dt;
    this.position.y += this.velocity.y * dt;

    const bounds = Bounds.fromExtents(extents);

    if (this.position.x - this.size < bounds.left) {
      this.velocity.x = -this.velocity.x;
      this.position.x = bounds.left + this.size;
    } else if (this.position.x + this.size > bounds.right) {
      this.velocity.x = -this.velocity.x;
      this.position.x = bounds.right - this.size;
    }

    if (this.position.y - this.size < bounds.top) {
      this.velocity.y = -this.velocity.y;
      this.position.y = bounds.top + this.size;
    } else if (this.position.y + this.size > bounds.bottom) {
      this.velocity.y = -this.velocity.y;
      this.position.y = bounds.bottom - this.size;
    }
  }
}

export default class Particles extends LineArt {
  draw(context: CanvasRenderingContext2D, extents: Extents, millis: number) {
    if (this.particles.length === 0) {
      this.particles = getInitialParticles(extents);
      this.logStats();
    }

    context.fillStyle = '#fafafa';
    context.fillRect(0, 0, extents.width, extents.height);

    super.draw(context, extents, millis);
  }

  // update(dt: number, extents: Extents) {}
}

function getInitialParticles(extents: Extents) {
  const colors = [
    '#f44336',
    '#e91e63',
    '#9c27b0',
    '#673ab7',
    '#3f51b5',
    '#2196f3',
    '#03a9f4',
    '#00bcd4',
    '#009688',
    '#4caf50',
    '#8bc34a',
    '#cddc39',
    '#ffeb3b',
    '#ffc107',
    '#ff9800',
    '#ff5722',
    '#795548',
    '#9e9e9e',
    '#607d8b',
  ];

  const numParticles = Math.round(
    Math.sqrt(extents.width * extents.height) / 10
  );
  const maxSize = Math.round(
    Math.sqrt((extents.width * extents.height) / 1000)
  );

  console.log('Seed');
  console.log(`numParticles: ${numParticles}`);
  console.log(`maxSize: ${maxSize}`);

  return [...Array(numParticles)].map(
    (_, i) =>
      new Particle(
        /* position */ new Point(
          getRandomBetween(0, extents.width),
          getRandomBetween(0, extents.height)
        ),
        /* velocity */ new Vector(
          getRandomBetween(-64, 64),
          getRandomBetween(-64, 64)
        ),
        /* color */ colors[i % colors.length],
        /* size */ getRandomBetween(4, maxSize)
      )
  );
}
