import BaseDrawable from './base-drawable';
import { Extents, Point, Vector } from './shared';
import { getRandomBetween } from './shared/utils';

export class Particle extends BaseDrawable {
  position: Point;
  velocity: Vector;
  size: number;
  color: string;

  constructor(position: Point, velocity: Vector, color: string, size: number) {
    super();
    this.position = position;
    this.velocity = velocity;
    this.size = size;
    this.color = color;
  }

  draw(context: CanvasRenderingContext2D, extents: Extents, millis: number) {
    context.beginPath();
    context.arc(this.position.x, this.position.y, this.size, 0, 2 * Math.PI);
    context.fillStyle = this.color;
    context.fill();
  }

  update(dt: number, extents: Extents) {
    this.position.x += this.velocity.x * dt;
    this.position.y += this.velocity.y * dt;
    if (this.position.x < 0) {
      this.position.x += extents.width;
    } else if (this.position.x > extents.width) {
      this.position.x -= extents.width;
    }
    if (this.position.y < 0) {
      this.position.y += extents.height;
    } else if (this.position.y > extents.height) {
      this.position.y -= extents.height;
    }
  }

  get mass() {
    return Math.PI * this.size ** 2;
  }
}

export default class LineArt extends BaseDrawable {
  private _last: number = 0;
  particles: Particle[] = [];

  draw(context: CanvasRenderingContext2D, extents: Extents, millis: number) {
    if (this.particles.length === 0) {
      this.particles = getInitialParticles(extents);
      this.logStats();
    }
    this.update((millis - this._last) / 1000, extents);
    this.particles.forEach((particle) =>
      particle.draw(context, extents, millis)
    );
    this._last = millis;
  }

  update(dt: number, extents: Extents) {
    this.particles.forEach((x) => x.update(dt, extents));

    this.particles.forEach((p1, i) => {
      this.particles
        .slice(i + 1)
        .filter((p2) => p1 !== p2 && isIntersecting(p1, p2))
        .forEach((p2) => {
          const collisionUnitVector = new Vector(
            p2.position.x - p1.position.x,
            p2.position.y - p1.position.y
          ).unit;
          const dv = new Vector(
            p1.velocity.x - p2.velocity.x,
            p1.velocity.y - p2.velocity.y
          );
          const speed = dv.dot(collisionUnitVector);
          if (speed < 0) {
            return;
          }

          const impulse = (2 * speed) / (p1.mass + p2.mass);
          p1.velocity = p1.velocity.add(
            collisionUnitVector.multiply(-impulse * p2.mass)
          );
          p2.velocity = p2.velocity.add(
            collisionUnitVector.multiply(impulse * p1.mass)
          );
        });
    });
  }

  logStats() {
    console.log(`Number of Particles: ${this.particles.length}`);
    const totalMass = this.particles.reduce((a, x) => a + x.mass, 0);
    console.log(`m: ${totalMass} (avg. ${totalMass / this.particles.length})`);
    const totalKineticEnergy = this.particles.reduce(
      (a, x) => a + 0.5 * x.mass * x.velocity.magnitude ** 2,
      0
    );
    console.log(
      `KE: ${totalKineticEnergy} (avg. ${
        totalKineticEnergy / this.particles.length
      })`
    );
    setInterval(() => {
      const kes = this.particles.map(
        (x) => 0.5 * x.mass * x.velocity.magnitude ** 2
      );
      console.log(`KE: max. ${Math.max(...kes)}, min. ${Math.min(...kes)}`);
      const velocities = this.particles.map((x) => x.velocity.magnitude);
      console.log(
        `v: avg. ${
          velocities.reduce((a, x) => a + x, 0) / velocities.length
        }, max. ${Math.max(...velocities)}, min. ${Math.min(...velocities)}`
      );
    }, 5000);
  }
}

function getInitialParticles(extents: Extents) {
  const colors = [
    '#f44336',
    '#e91e63',
    '#9c27b0',
    '#673ab7',
    '#3f51b5',
    '#2196f3',
    '#03a9f4',
    '#00bcd4',
    '#009688',
    '#4caf50',
    '#8bc34a',
    '#cddc39',
    '#ffeb3b',
    '#ffc107',
    '#ff9800',
    '#ff5722',
    '#795548',
    '#9e9e9e',
    '#607d8b',
  ];

  const numParticles = Math.round(
    Math.sqrt(extents.width * extents.height) / 10
  );

  return [...Array(numParticles)].map(
    (_, i) =>
      new Particle(
        /* position */ new Point(
          getRandomBetween(0, extents.width),
          getRandomBetween(0, extents.height)
        ),
        /* velocity */ new Vector(
          getRandomBetween(-64, 64),
          getRandomBetween(-64, 64)
        ),
        /* color */ colors[i % colors.length],
        /* size */ getRandomBetween(4, 16)
      )
  );
}

function isIntersecting(p1: Particle, p2: Particle) {
  return (
    (p1.position.x - p2.position.x) ** 2 +
      (p1.position.y - p2.position.y) ** 2 <=
    (p1.size + p2.size) ** 2
  );
}
