import * as React from 'react';

import './App.css';
import LineArt from './line-art';
import Canvas from './Canvas';
import { Extents } from './shared';

function LineArtCanvas() {
  const [game] = React.useState(new LineArt());

  const draw = React.useCallback(
    (context: CanvasRenderingContext2D, extents: Extents, millis: number) =>
      game.draw(context, extents, millis),
    [game]
  );

  return <Canvas draw={draw} />;
}

export default LineArtCanvas;
