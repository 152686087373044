import * as React from 'react';

import BlocksCanvas from './BlocksCanvas';
import './App.css';
import LineArtCanvas from './LineArtCanvas';
import ParticlesCanvas from './ParticlesCanvas';

const PAGES = 3;

function App() {
  const [page, setPage] = React.useState(PAGES - 1);
  const next = () => setPage((state) => Math.max(0, state - 1));
  const prev = () => setPage((state) => Math.min(PAGES - 1, state + 1));

  const onWheel = (e: React.WheelEvent<HTMLDivElement>) => {
    if (e.deltaY > 0) {
      next();
    } else {
      prev();
    }
  };

  return (
    <div className="App" onWheel={onWheel}>
      {page === 2 && <ParticlesCanvas />}
      {page === 1 && <LineArtCanvas />}
      {page === 0 && <BlocksCanvas />}
    </div>
  );
}

export default App;
