import * as React from 'react';

import './Canvas.css';
import { Extents } from './shared';

interface CanvasProps
  extends React.DetailedHTMLProps<
    React.CanvasHTMLAttributes<HTMLCanvasElement>,
    HTMLCanvasElement
  > {
  draw: (
    context: CanvasRenderingContext2D,
    extents: Extents,
    last: number
  ) => void;
}

export default function Canvas({ draw, ...rest }: CanvasProps) {
  const ref = React.useRef<HTMLCanvasElement>(null);

  React.useEffect(() => {
    const canvas = ref.current;
    const context = canvas?.getContext('2d');
    if (canvas && context) {
      const ratio = getPixelRatio(context);
      const width = Number(
        getComputedStyle(canvas).getPropertyValue('width').slice(0, -2)
      );
      let height = Number(
        getComputedStyle(canvas).getPropertyValue('height').slice(0, -2)
      );

      canvas.width = width * ratio;
      canvas.height = height * ratio;

      canvas.style.width = `${width}px`;
      canvas.style.height = `${height}px`;

      let requestId: number;
      const extents = new Extents(canvas.width, canvas.height);
      let last = 0;
      const render = (millis: number = 0) => {
        draw(context, extents, last);
        last = millis;
        requestId = requestAnimationFrame(render);
      };
      render();
      return () => cancelAnimationFrame(requestId);
    }
  }, [ref, draw]);

  return <canvas {...rest} className="Canvas" ref={ref} />;
}

function getPixelRatio(
  context: CanvasRenderingContext2D & {
    backingStorePixelRatio?: number;
    webkitBackingStorePixelRatio?: number;
    mozBackingStorePixelRatio?: number;
    msBackingStorePixelRatio?: number;
    oBackingStorePixelRatio?: number;
  }
) {
  var backingStore =
    context.backingStorePixelRatio ||
    context.webkitBackingStorePixelRatio ||
    context.mozBackingStorePixelRatio ||
    context.msBackingStorePixelRatio ||
    context.oBackingStorePixelRatio ||
    1;

  return (window.devicePixelRatio || 1) / backingStore;
}
